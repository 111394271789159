import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { axiosAuth } from '../../helpers/useAuth';
import { GET_ADS_REQUEST } from '../actions';
import { getAdsError, getAdSuccess } from './actions';

// ASYNC CALL
const getAdsAsync = async () => {
  try {
    const { data } = await axiosAuth().get(`/ads`);
    return data;
  } catch (error) {
    return error;
  }
};

// WORKER
function* getAds() {
  try {
    const adData = yield call(getAdsAsync);
    yield put(getAdSuccess(adData));
  } catch (error) {
    yield put(getAdsError(error));
  }
}

// WATCHER
export function* watchGetAds() {
  yield takeEvery(GET_ADS_REQUEST, getAds);
}

export default function* rootSaga() {
  yield all([fork(watchGetAds)]);
}
