import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import authUser from './auth/reducer';
import brands from './brands/reducer';
import ads from './ads/reducer';
import users from './users/reducer';
import upload from './upload/reducer';
import socials from './socials/reducer';
import exportReports from './exportReports/reducer';

const reducers = combineReducers({
  menu,
  settings,
  authUser,
  brands,
  ads,
  users,
  upload,
  socials,
  exportReports,
});

export default reducers;
