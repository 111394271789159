/* eslint-disable no-unused-vars */
import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import brandSagas from './brands/saga';
import adSagas from './ads/saga';
import usersSagas from './users/saga';
import uploadSagas from './upload/saga';
import socialSagas from './socials/saga';
import exportSagas from './exportReports/saga';

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    brandSagas(),
    adSagas(),
    usersSagas(),
    uploadSagas(),
    socialSagas(),
    exportSagas(),
  ]);
}
