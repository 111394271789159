import {
  GET_ADS_ERROR,
  GET_ADS_REQUEST,
  GET_ADS_SUCCESS,
} from '../actions';

const INIT_STATE = {
  loading: false,
  data: [],
  error: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ADS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ADS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...action.payload.ads],
        error: '',
      };
    case GET_ADS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return { ...state };
  }
};
