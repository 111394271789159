import {
  GET_USERS_ERROR,
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  GET_USER_ERROR,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  REMOVE_USER_ERROR,
  REMOVE_USER_REQUEST,
  REMOVE_USER_SUCCESS,
} from '../actions';

const INIT_STATE = {
  loading: false,
  data: [],
  error: '',
  selectedUser: {},
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...action.payload.users],
        error: '',
      };
    case GET_USERS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case GET_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedUser: action.payload.user,
        error: '',
      };
    case GET_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case REMOVE_USER_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case REMOVE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        data: [...action.payload.data],
      };
    case REMOVE_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return { ...state };
  }
};
