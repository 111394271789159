import { UPLOAD_ERROR, UPLOAD_REQUEST, UPLOAD_SUCCESS } from '../actions';

export const uploadRequest = (id, data) => ({
  type: UPLOAD_REQUEST,
  payload: { id, data },
});

export const uploadError = (error) => ({
  type: UPLOAD_ERROR,
  payload: { error },
});

export const uploadSuccess = (data) => ({
  type: UPLOAD_SUCCESS,
  payload: { data },
});
