/* eslint-disable import/no-unresolved */
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { EXPORT_REPORTS_REQUEST } from 'redux/actions';
import { axiosAuth } from '../../helpers/useAuth';
import { exportReportsError, exportReportsSuccess } from './actions';

// ASYNC CALL
const exportReportsAsync = async ({ body }) => {
  const query = `?start_date=${body.start_date}&end_date=${
    body.end_date
  }&platforms=${encodeURIComponent(
    JSON.stringify(body.platforms)
  )}&brands=${encodeURIComponent(JSON.stringify(body.brands))}`;

  try {
    const response = await axiosAuth().get(`/exportreports${query}`, {
      responseType: 'blob',
    });

    const blob = response.data;
    const filename = `Frontrunner Report Export - ${body.start_date} to ${body.end_date}.csv`;
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement('a');

    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();

    return true;
  } catch (error) {
    throw new Error(error);
  }
};

// WORKER
function* exportReports({ payload }) {
  try {
    yield call(exportReportsAsync, payload);
    yield put(exportReportsSuccess());
    payload.callback();
  } catch (error) {
    yield put(exportReportsError(error));
  }
}

// WATCHER
export function* watchExportReports() {
  yield takeEvery(EXPORT_REPORTS_REQUEST, exportReports);
}

export default function* rootSaga() {
  yield all([fork(watchExportReports)]);
}
