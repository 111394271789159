import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { axiosAuth } from '../../helpers/useAuth';
import { UPLOAD_REQUEST } from '../actions';
import { uploadError, uploadSuccess } from './actions';

const uploadImageAsync = async (uploadData) => {
  try {
    const { data } = await axiosAuth().post(`/upload`, uploadData);
    console.log('UPLOADFILEASYNC:\n***', { data, uploadData });
    return data;
  } catch (error) {
    return error;
  }
};

// WORKER
function* uploadImage({ payload }) {
  const newUploadData = new FormData();
  newUploadData.append('files', payload.data.data);
  newUploadData.append('refId', payload.id);
  newUploadData.append('ref', 'user');
  newUploadData.append('source', 'users-permissions');
  newUploadData.append('field', 'photo');

  try {
    const uploadData = yield call(uploadImageAsync, newUploadData);
    yield put(uploadSuccess(uploadData));
    yield put(payload.data.setFieldValue(uploadData));
  } catch (error) {
    yield put(uploadError(error));
  }
}

// WATCHER
function* watchUploadImage() {
  yield takeEvery(UPLOAD_REQUEST, uploadImage);
}

export default function* rootSaga() {
  yield all([fork(watchUploadImage)]);
}
