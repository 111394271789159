import { UPLOAD_ERROR, UPLOAD_REQUEST, UPLOAD_SUCCESS } from '../actions';

const INIT_STATE = {
  loading: false,
  data: [],
  error: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPLOAD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPLOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...action.payload.data],
        error: '',
      };
    case UPLOAD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};
