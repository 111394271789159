import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { axiosAuth } from '../../helpers/useAuth';
import {
  GET_BRAND_TWITTER_AUTH_REQUEST,
  FB_AUTH_REQUEST,
  TK_AUTH_REQUEST,
  TW_AUTH_REQUEST,
  TW_TOKEN_REQUEST,
} from '../actions';
import {
  getBrandTwitterAuthError,
  fbAuthSuccess,
  fbAuthError,
  tkAuthSuccess,
  tkAuthError,
  twAuthError,
  twAuthSuccess,
  twTokenSuccess,
  twTokenError,
} from './actions';

// ASYNC CALL
const getBrandTwitterAuthAsync = async (id) => {
  try {
    const { data } = await axiosAuth().get(`/twitterauth/start/${id}`);
    return data;
  } catch (error) {
    return error;
  }
};

// WORKER
function* getBrandTwitterAuth({ payload }) {
  try {
    const twitterAuthData = yield call(getBrandTwitterAuthAsync, payload.id);

    window.location.href = twitterAuthData?.redirectUrl;
  } catch (error) {
    yield put(getBrandTwitterAuthError(error));
  }
}

// WATCHER
export function* watchGetBrandTwitterAuth() {
  yield takeEvery(GET_BRAND_TWITTER_AUTH_REQUEST, getBrandTwitterAuth);
}

// ASYNC CALL
const fbAuthAsync = async (body) => {
  try {
    const { data } = await axiosAuth().post('/brands/facebooktoken', body);
    return data;
  } catch (error) {
    throw new Error(error);
  }
};

// WORKER
function* fbAuth({ payload }) {
  try {
    const fbAuthData = yield call(fbAuthAsync, payload.body);
    yield put(fbAuthSuccess(fbAuthData));
  } catch (error) {
    yield put(fbAuthError());
  }
}

// WATCHER
export function* watchFbAuth() {
  yield takeEvery(FB_AUTH_REQUEST, fbAuth);
}

// ASYNC CALL
const tkAuthAsync = async (body) => {
  try {
    const { data } = await axiosAuth().post(
      '/brands/exchangeTikTokToken',
      body
    );
    return data;
  } catch (error) {
    throw new Error(error);
  }
};

// WORKER
function* tkAuth({ payload }) {
  try {
    const data = yield call(tkAuthAsync, payload.body);
    yield put(tkAuthSuccess(data));
  } catch (error) {
    yield put(tkAuthError());
  }
}

// WATCHER
export function* watchTkAuth() {
  yield takeEvery(TK_AUTH_REQUEST, tkAuth);
}

// ASYNC CALL
const twitterAuthAsync = async (callback) => {
  try {
    const { data } = await axiosAuth().post('/brands/gettwittertoken', {
      callback,
    });

    if (data) {
      const searchParams = new URLSearchParams(data);
      const oauthToken = searchParams.get('oauth_token');
      const oauthTokenSecret = searchParams.get('oauth_token_secret');

      const storedData = {};
      storedData[oauthToken] = oauthTokenSecret;

      localStorage.setItem('twitterOauth', JSON.stringify(storedData));
      window.location.href = `https://api.twitter.com/oauth/authorize?oauth_token=${oauthToken}`;
    }

    return data;
  } catch (error) {
    throw new Error(error);
  }
};

// WORKER
function* twitterAuth({ payload }) {
  try {
    const data = yield call(twitterAuthAsync, payload.callback);
    yield put(twAuthSuccess(data));
  } catch (error) {
    yield put(twAuthError());
  }
}

// WATCHER
export function* watchTwitterAuth() {
  yield takeEvery(TW_AUTH_REQUEST, twitterAuth);
}

// ASYNC CALL
const twitterTokenAsync = async (body) => {
  try {
    const { data } = await axiosAuth().post(
      '/brands/exchangetwittertoken',
      body
    );
    return data;
  } catch (error) {
    throw new Error(error);
  }
};

// WORKER
function* twitterToken({ payload }) {
  try {
    const data = yield call(twitterTokenAsync, payload.body);
    yield put(twTokenSuccess(data));
  } catch (error) {
    yield put(twTokenError());
  }
}

// WATCHER
export function* watchTwitterToken() {
  yield takeEvery(TW_TOKEN_REQUEST, twitterToken);
}

export default function* rootSaga() {
  yield all([fork(watchGetBrandTwitterAuth)]);
  yield all([fork(watchFbAuth)]);
  yield all([fork(watchTkAuth)]);
  yield all([fork(watchTwitterAuth)]);
  yield all([fork(watchTwitterToken)]);
}
