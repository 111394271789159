import {
  GET_ADS_ERROR,
  GET_ADS_REQUEST,
  GET_ADS_SUCCESS,
} from '../actions';

export const getAdsRequest = () => ({
  type: GET_ADS_REQUEST,
});

export const getAdSuccess = (ads) => ({
  type: GET_ADS_SUCCESS,
  payload: { ads },
});

export const getAdsError = (error) => ({
  type: GET_ADS_ERROR,
  payload: { error },
});
