import {
  GET_BRAND_TWITTER_AUTH_ERROR,
  GET_BRAND_TWITTER_AUTH_REQUEST,
  GET_BRAND_TWITTER_AUTH_SUCCESS,
  FB_AUTH_ERROR,
  FB_AUTH_REQUEST,
  FB_AUTH_SUCCESS,
  TK_AUTH_ERROR,
  TK_AUTH_REQUEST,
  TK_AUTH_SUCCESS,
  TW_AUTH_ERROR,
  TW_AUTH_REQUEST,
  TW_AUTH_SUCCESS,
  TW_TOKEN_ERROR,
  TW_TOKEN_REQUEST,
  TW_TOKEN_SUCCESS,
} from '../actions';

const INIT_STATE = {
  loading: false,
  data: [],
  error: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_BRAND_TWITTER_AUTH_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_BRAND_TWITTER_AUTH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        data: [...action.payload.data],
      };
    case GET_BRAND_TWITTER_AUTH_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case FB_AUTH_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FB_AUTH_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };
    case FB_AUTH_ERROR:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };
    case TK_AUTH_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TK_AUTH_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };
    case TK_AUTH_ERROR:
      return {
        ...state,
        loading: false,
        data: false,
      };
    case TW_AUTH_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TW_AUTH_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };
    case TW_AUTH_ERROR:
      return {
        ...state,
        loading: false,
        data: false,
      };
    case TW_TOKEN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TW_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };
    case TW_TOKEN_ERROR:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };
    default:
      return state;
  }
};
