/* eslint-disable import/no-cycle */
/* SETTINGS */
export const CHANGE_LOCALE = 'CHANGE_LOCALE';

/* AUTH */
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';
export const LOGOUT_USER = 'LOGOUT_USER';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
export const AUTH_CHECK = 'AUTH_CHECK';
export const AUTH_CHECK_SUCCESS = 'AUTH_CHECK_SUCCESS';
export const AUTH_CHECK_ERROR = 'AUTH_CHECK_ERROR';

/* MENU */
export const MENU_SET_CLASSNAMES = 'MENU_SET_CLASSNAMES';
export const MENU_CONTAINER_ADD_CLASSNAME = 'MENU_CONTAINER_ADD_CLASSNAME';
export const MENU_CLICK_MOBILE_MENU = 'MENU_CLICK_MOBILE_MENU';
export const MENU_CHANGE_DEFAULT_CLASSES = 'MENU_CHANGE_DEFAULT_CLASSES';
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  'MENU_CHANGE_HAS_SUB_ITEM_STATUS';

export * from './menu/actions';
export * from './settings/actions';
export * from './auth/actions';

/* BRANDS */
export const GET_BRANDS_REQUEST = 'GET_BRANDS_REQUEST';
export const GET_BRANDS_SUCCESS = 'GET_BRANDS_SUCCESS';
export const GET_BRANDS_ERROR = 'GET_BRANDS_ERROR';
export const GET_BRANDS_DASHBOARD_REQUEST = 'GET_BRANDS_DASHBOARD_REQUEST';
export const GET_BRANDS_DASHBOARD_SUCCESS = 'GET_BRANDS_DASHBOARD_SUCCESS';
export const GET_BRANDS_DASHBOARD_ERROR = 'GET_BRANDS_DASHBOARD_ERROR';
/* SPECIFIC BRAND */
export const GET_BRAND_REQUEST = 'GET_BRAND_REQUEST';
export const GET_BRAND_SUCCESS = 'GET_BRAND_SUCCESS';
export const GET_BRAND_ERROR = 'GET_BRAND_ERROR';
export const UPDATE_BRAND_REQUEST = 'UPDATE_BRAND_REQUEST';
export const UPDATE_BRAND_SUCCESS = 'UPDATE_BRAND_SUCCESS';
export const UPDATE_BRAND_ERROR = 'UPDATE_BRAND_ERROR';
export const NEW_BRAND_REQUEST = 'NEW_BRAND_REQUEST';
export const NEW_BRAND_SUCCESS = 'NEW_BRAND_SUCCESS';
export const NEW_BRAND_ERROR = 'NEW_BRAND_ERROR';
export const REMOVE_BRAND_REQUEST = 'REMOVE_BRAND_REQUEST';
export const REMOVE_BRAND_SUCCESS = 'REMOVE_BRAND_SUCCESS';
export const REMOVE_BRAND_ERROR = 'REMOVE_BRAND_ERROR';
/* BENCHMARKS */
export const NEW_BENCHMARK_SUCCESS = 'NEW_BENCHMARK_SUCCESS';
export const NEW_BENCHMARK_ERROR = 'NEW_BENCHMARK_ERROR';
export const NEW_BENCHMARK_REQUEST = 'NEW_BENCHMARK_REQUEST';
export const UPDATE_BENCHMARK_REQUEST = 'UPDATE_BENCHMARK_REQUEST';
export const UPDATE_BENCHMARK_SUCCESS = 'UPDATE_BENCHMARK_SUCCESS';
export const UPDATE_BENCHMARK_ERROR = 'UPDATE_BENCHMARK_ERROR';
export const REMOVE_BENCHMARK_REQUEST = 'REMOVE_BENCHMARK_REQUEST';
export const REMOVE_BENCHMARK_SUCCESS = 'REMOVE_BENCHMARK_SUCCESS';
export const REMOVE_BENCHMARK_ERROR = 'REMOVE_BENCHMARK_ERROR';
/* RESULT TYPES */
export const GET_RESULT_TYPES_REQUEST = 'GET_RESULT_TYPES_REQUEST';
export const GET_RESULT_TYPES_SUCCESS = 'GET_RESULT_TYPES_SUCCESS';
export const GET_RESULT_TYPES_ERROR = 'GET_RESULT_TYPES_ERROR';
/* TWITTER AUTH */
export const GET_BRAND_TWITTER_AUTH_REQUEST = 'GET_BRAND_TWITTER_AUTH_REQUEST';
export const GET_BRAND_TWITTER_AUTH_SUCCESS = 'GET_BRAND_TWITTER_AUTH_SUCCESS';
export const GET_BRAND_TWITTER_AUTH_ERROR = 'GET_BRAND_TWITTER_AUTH_ERROR';
export const TW_AUTH_REQUEST = 'TW_AUTH_REQUEST';
export const TW_AUTH_SUCCESS = 'TW_AUTH_SUCCESS';
export const TW_AUTH_ERROR = 'TW_AUTH_ERROR';
export const TW_TOKEN_REQUEST = 'TW_TOKEN_REQUEST';
export const TW_TOKEN_SUCCESS = 'TW_TOKEN_SUCCESS';
export const TW_TOKEN_ERROR = 'TW_TOKEN_ERROR';
/* FACEBOOK AUTH */
export const FB_AUTH_REQUEST = 'FB_AUTH_REQUEST';
export const FB_AUTH_SUCCESS = 'FB_AUTH_SUCCESS';
export const FB_AUTH_ERROR = 'FB_AUTH_ERROR';
/* TikTok AUTH */
export const TK_AUTH_REQUEST = 'TK_AUTH_REQUEST';
export const TK_AUTH_SUCCESS = 'TK_AUTH_SUCCESS';
export const TK_AUTH_ERROR = 'TK_AUTH_ERROR';
/* GET BRAND DASHBOARD APPROVE/DECLINE AD */
export const APPROVE_BRANDAD_REQUEST = 'APPROVE_BRANDAD_REQUEST';
export const APPROVE_BRANDAD_SUCCESS = 'APPROVE_BRANDAD_SUCCESS';
export const APPROVE_BRANDAD_ERROR = 'APPROVE_BRANDAD_ERROR';
export const DECLINE_BRANDAD_REQUEST = 'DECLINE_BRANDAD_REQUEST';
export const DECLINE_BRANDAD_SUCCESS = 'DECLINE_BRANDAD_SUCCESS';
export const DECLINE_BRANDAD_ERROR = 'DECLINE_BRANDAD_ERROR';
/* REFRESH ADS */
export const REFRESH_ADS_REQUEST = 'REFRESH_ADS_REQUEST';
export const REFRESH_ADS_SUCCESS = 'REFRESH_ADS_SUCCESS';
export const REFRESH_ADS_ERROR = 'REFRESH_ADS_ERROR';

/* ADS */
export const GET_ADS_REQUEST = 'GET_ADS_REQUEST';
export const GET_ADS_SUCCESS = 'GET_ADS_SUCCESS';
export const GET_ADS_ERROR = 'GET_ADS_ERROR';

/* USERS */
export const GET_USERS_REQUEST = 'GET_USERS_REQUEST';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_ERROR = 'GET_USERS_ERROR';
/* SPECIFIC USER */
export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_ERROR = 'GET_USER_ERROR';
export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';
export const REMOVE_USER_REQUEST = 'REMOVE_USER_REQUEST';
export const REMOVE_USER_SUCCESS = 'REMOVE_USER_SUCCESS';
export const REMOVE_USER_ERROR = 'REMOVE_USER_ERROR';

/* UPLOAD */
export const UPLOAD_REQUEST = 'UPLOAD_REQUEST';
export const UPLOAD_SUCCESS = 'UPLOAD_SUCCESS';
export const UPLOAD_ERROR = 'UPLOAD_ERROR';

/* EXPORT REPORTS */
export const EXPORT_REPORTS_REQUEST = 'EXPORT_REPORTS_REQUEST';
export const EXPORT_REPORTS_SUCCESS = 'EXPORT_REPORTS_SUCCESS';
export const EXPORT_REPORTS_ERROR = 'EXPORT_REPORTS_ERROR';
