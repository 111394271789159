import {
  GET_BRAND_TWITTER_AUTH_ERROR,
  GET_BRAND_TWITTER_AUTH_REQUEST,
  GET_BRAND_TWITTER_AUTH_SUCCESS,
  FB_AUTH_ERROR,
  FB_AUTH_REQUEST,
  FB_AUTH_SUCCESS,
  TK_AUTH_REQUEST,
  TK_AUTH_ERROR,
  TK_AUTH_SUCCESS,
  TW_AUTH_ERROR,
  TW_AUTH_REQUEST,
  TW_AUTH_SUCCESS,
  TW_TOKEN_ERROR,
  TW_TOKEN_REQUEST,
  TW_TOKEN_SUCCESS,
} from '../actions';

export const getBrandTwitterAuthRequest = (id, history) => ({
  type: GET_BRAND_TWITTER_AUTH_REQUEST,
  payload: { id, history },
});

export const getBrandTwitterAuthSuccess = (data) => ({
  type: GET_BRAND_TWITTER_AUTH_SUCCESS,
  payload: { data },
});

export const getBrandTwitterAuthError = (error) => ({
  type: GET_BRAND_TWITTER_AUTH_ERROR,
  payload: { error },
});

export const fbAuthRequest = (body) => {
  return {
    type: FB_AUTH_REQUEST,
    payload: { body },
  };
};

export const fbAuthSuccess = (data) => {
  return {
    type: FB_AUTH_SUCCESS,
    payload: { data },
  };
};

export const fbAuthError = () => {
  return {
    type: FB_AUTH_ERROR,
    payload: { data: false },
  };
};

export const tkAuthRequest = (body) => {
  return {
    type: TK_AUTH_REQUEST,
    payload: { body },
  };
};

export const tkAuthSuccess = (data) => {
  return {
    type: TK_AUTH_SUCCESS,
    payload: { data },
  };
};

export const tkAuthError = () => {
  return {
    type: TK_AUTH_ERROR,
    payload: { data: false },
  };
};

export const twAuthRequest = (callback) => {
  return {
    type: TW_AUTH_REQUEST,
    payload: { callback },
  };
};

export const twAuthSuccess = (data) => {
  return {
    type: TW_AUTH_SUCCESS,
    payload: { data },
  };
};

export const twAuthError = () => {
  return {
    type: TW_AUTH_ERROR,
    payload: { data: false },
  };
};

export const twTokenRequest = (body) => {
  return {
    type: TW_TOKEN_REQUEST,
    payload: { body },
  };
};

export const twTokenSuccess = (data) => {
  return {
    type: TW_TOKEN_SUCCESS,
    payload: { data },
  };
};

export const twTokenError = () => {
  return {
    type: TW_TOKEN_ERROR,
    payload: { data: false },
  };
};
