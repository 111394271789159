import axios from 'axios';
import { url } from '../constants/defaultValues';

// GET TOKEN FROM LOCALSTORAGE
export const getToken = () => {
  const data = localStorage.getItem('frontrunner_current_user');
  const parsedData = JSON.parse(data);
  const token = parsedData.frontrunnerAcc;
  return token;
};

// SET TOKEN FROM LOCALSTORAGE
export const setToken = (payload) => {
  return localStorage.setItem('frontrunner_current_user', payload);
};

// REMOVE TOKEN FROM LOCALSTORAGE
export const removeToken = () => {
  return localStorage.removeItem('frontrunner_current_user');
};

// SET HEADERS ON AXIOS REQUEST
export const axiosAuth = () => {
  // Token keeps returning with "" so we need to remove them
  const token = getToken();

  let finalToken;

  if (token) {
    finalToken = token.replace(/['"]+/g, '');
  } else {
    finalToken = token;
  }

  return axios.create({
    baseURL: url,
    headers: {
      Authorization: `Bearer ${token ? finalToken : token}`,
    },
  });
};
