import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { axiosAuth } from '../../helpers/useAuth';
import {
  GET_USERS_REQUEST,
  GET_USER_REQUEST,
  REMOVE_USER_REQUEST,
  UPDATE_USER_REQUEST,
} from '../actions';
import {
  getUserError,
  getUsersError,
  getUsersSuccess,
  getUserSuccess,
  removeUserError,
  updateUserError,
} from './actions';

// ASYNC CALL
const getUsersAsync = async () => {
  try {
    const { data } = await axiosAuth().get(`/users`);
    return data;
  } catch (error) {
    return error;
  }
};

// WORKER
function* getUsers() {
  try {
    const usersData = yield call(getUsersAsync);
    yield put(getUsersSuccess(usersData));
  } catch (error) {
    yield put(getUsersError(error));
  }
}

// WATCHER
export function* watchGetUsers() {
  yield takeEvery(GET_USERS_REQUEST, getUsers);
}

const getUserAsync = async (id) => {
  try {
    const { data } = await axiosAuth().get(`/users/${id}`);
    return data;
  } catch (error) {
    return error;
  }
};

// WORKER
function* getUser({ payload }) {
  try {
    const userData = yield call(getUserAsync, payload.id);
    yield put(getUserSuccess(userData));
  } catch (error) {
    yield put(getUserError(error));
  }
}

// WATCHER
export function* watchGetUser() {
  yield takeEvery(GET_USER_REQUEST, getUser);
}

const uploadFileAsync = async (uploadData) => {
  try {
    const { data } = await axiosAuth().post(`/upload`, uploadData);
    return data;
  } catch (error) {
    return error;
  }
};

const updateUserAsync = async ({ id, body }) => {
  try {
    // TODO -- change pathname to brands when BE schema changes
    const { data } = await axiosAuth().put(`/users/${id}`, body);

    return data;
  } catch (error) {
    return error;
  }
};

// WORKER
function* updateUser({ payload }) {
  try {
    // if brand is updating profile pic
    if (payload.body.profilePhoto) {
      const uploadData = new FormData();
      uploadData.append('files', payload.body.profilePhoto);
      uploadData.append('refId', payload.id);
      uploadData.append('ref', 'user');
      uploadData.append('source', 'users-permissions');
      uploadData.append('field', 'profilePhoto');

      // upload request
      const imageData = yield call(uploadFileAsync, uploadData);
      // add returned form data to use in form submission
      payload.body.profilePhoto = imageData[0];
    }
    const userData = yield call(updateUserAsync, payload);
    yield put(getUserSuccess(userData));
  } catch (error) {
    yield put(updateUserError(error));
  }
}

// WATCHER
export function* watchUpdateUser() {
  yield takeEvery(UPDATE_USER_REQUEST, updateUser);
}

const removeUserAsync = async (id) => {
  try {
    const { data } = await axiosAuth().delete(`/users/${id}`);

    return data;
  } catch (error) {
    throw new Error(error);
  }
};

// WORKER
function* removeUser({ payload: { id, setUsersData, usersDataCopy } }) {
  try {
    yield call(removeUserAsync, id);
  } catch (error) {
    yield put(removeUserError(error));
    yield call(setUsersData, usersDataCopy);
  }
}

// WATCHER
export function* watchRemoveUser() {
  yield takeEvery(REMOVE_USER_REQUEST, removeUser);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetUsers),
    fork(watchGetUser),
    fork(watchUpdateUser),
    fork(watchRemoveUser),
  ]);
}
