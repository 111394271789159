export const defaultMenuType = 'menu-default';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'en';
export const localeOptions = [
  { id: 'en', name: 'English - LTR', direction: 'ltr' },
  { id: 'es', name: 'Español', direction: 'ltr' },
  { id: 'enrtl', name: 'English - RTL', direction: 'rtl' },
];

export const firebaseConfig = {
  apiKey: 'AIzaSyBBksq-Asxq2M4Ot-75X19IyrEYJqNBPcg',
  authDomain: 'gogo-react-login.firebaseapp.com',
  databaseURL: 'https://gogo-react-login.firebaseio.com',
  projectId: 'gogo-react-login',
  storageBucket: 'gogo-react-login.appspot.com',
  messagingSenderId: '216495999563',
};

export const adminRoot = '/app';
export const url = process.env.REACT_APP_BASE_URL;

export const buyUrl = 'https://1.envato.market/k4z0';
export const searchPath = `${adminRoot}/#`;
export const servicePath = 'https://api.coloredstrategies.com';

export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = true;
export const defaultColor = 'light.redruby';
export const isDarkSwitchActive = true;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const isAuthGuardActive = true;
export const colors = [
  'bluenavy',
  'blueyale',
  'blueolympic',
  'greenmoss',
  'greenlime',
  'purplemonster',
  'orangecarrot',
  'redruby',
  'yellowgranola',
  'greysteel',
];
export const isAdActive = 'active';

export const RESULT_OPTIONS = [
  { label: 'Clicks', value: 'clicks', key: 0 },
  { label: 'Comments', value: 'comments', key: 1 },
  { label: 'Engagements', value: 'engagements', key: 2 },
  { label: 'Video Views', value: 'video_views', key: 3 },
];

export const BENCHMARK_MEDIA_OPTIONS = [
  { label: 'All', value: '', key: 0 },
  { label: 'Static', value: 'static', key: 1 },
  { label: 'Video', value: 'video', key: 2 },
];

export const BENCHMARK_PLATFORM_OPTIONS = [
  { label: 'All', value: '', key: 0 },
  { label: 'Facebook', value: 'facebook', key: 1 },
  { label: 'Instagram', value: 'instagram', key: 2 },
  { label: 'Twitter', value: 'twitter', key: 3 },
  { label: 'TikTok', value: 'tiktok', key: 4 },
];

export const benchmarkResultTypes = {
  engagement_rate: 'engagement_rate',
  cost_per_engagement: 'cost_per_engagement',
};

export const socialMediaSelectData = [
  { label: 'Facebook', value: 'facebook', key: 0 },
  { label: 'Instagram', value: 'instagram', key: 1 },
  { label: 'Twitter', value: 'twitter', key: 2 },
  { label: 'TikTok', value: 'tiktok', key: 3 },
];

export const DEFAULT_TABLE_LIMIT = 20;
export const INITIAL_ERROR_STATE = { error: '' };
export const defaultDisplayPic = '/assets/img/products/marble-cake-thumb.jpg';
