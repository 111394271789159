import {
  GET_USERS_ERROR,
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  GET_USER_ERROR,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  REMOVE_USER_ERROR,
  REMOVE_USER_REQUEST,
  REMOVE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  UPDATE_USER_REQUEST,
} from '../actions';

export const getUsersRequest = () => ({
  type: GET_USERS_REQUEST,
});

export const getUsersSuccess = (users) => ({
  type: GET_USERS_SUCCESS,
  payload: { users },
});

export const getUsersError = (error) => ({
  type: GET_USERS_ERROR,
  payload: { error },
});

export const getUserRequest = (id) => ({
  type: GET_USER_REQUEST,
  payload: { id },
});

export const getUserSuccess = (user) => ({
  type: GET_USER_SUCCESS,
  payload: { user },
});

export const getUserError = (error) => ({
  type: GET_USER_ERROR,
  payload: { error },
});

export const updateUserRequest = (id, body) => ({
  type: UPDATE_USER_REQUEST,
  payload: { id, body },
});

export const updateUserError = (error) => ({
  type: UPDATE_USER_ERROR,
  payload: { error },
});

export const removeUserRequest = (id, setUsersData, usersDataCopy) => ({
  type: REMOVE_USER_REQUEST,
  payload: { id, setUsersData, usersDataCopy },
});

export const removeUserError = (error) => ({
  type: REMOVE_USER_ERROR,
  payload: { error },
});

export const removeUserSuccess = (data) => ({
  type: REMOVE_USER_SUCCESS,
  payload: { data },
});
