import {
  EXPORT_REPORTS_ERROR,
  EXPORT_REPORTS_REQUEST,
  EXPORT_REPORTS_SUCCESS,
} from '../actions';

export const exportReportsRequest = (body, callback) => {
  return {
    type: EXPORT_REPORTS_REQUEST,
    payload: { body, callback },
  };
};

export const exportReportsError = (error) => {
  return {
    type: EXPORT_REPORTS_ERROR,
    payload: { error },
  };
};

export const exportReportsSuccess = () => {
  return {
    type: EXPORT_REPORTS_SUCCESS,
  };
};
