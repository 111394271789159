// eslint-disable-next-line
import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  LOGIN_USER_ERROR,
  REGISTER_USER_ERROR,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  AUTH_CHECK_SUCCESS,
  AUTH_CHECK_ERROR,
  AUTH_CHECK,
} from '../actions';

export const authCheck = () => ({
  type: AUTH_CHECK,
});

export const authCheckSuccess = (user) => ({
  type: AUTH_CHECK_SUCCESS,
  payload: { user },
});

export const authCheckError = (authorized, error) => ({
  type: AUTH_CHECK_ERROR,
  payload: { authorized, error },
});

export const loginUser = (user, history) => ({
  type: LOGIN_USER,
  payload: { user, history },
});
export const loginUserSuccess = (user) => ({
  type: LOGIN_USER_SUCCESS,
  payload: user,
});
export const loginUserError = (message) => ({
  type: LOGIN_USER_ERROR,
  payload: { message },
});

export const forgotPassword = (forgotUserMail, history, isLoggedIn) => ({
  type: FORGOT_PASSWORD,
  payload: { forgotUserMail, history, isLoggedIn },
});
export const forgotPasswordSuccess = (forgotUserMail) => ({
  type: FORGOT_PASSWORD_SUCCESS,
  payload: forgotUserMail,
});
export const forgotPasswordError = (message) => ({
  type: FORGOT_PASSWORD_ERROR,
  payload: { message },
});

export const resetPassword = ({
  code,
  password,
  passwordConfirmation,
  history,
}) => ({
  type: RESET_PASSWORD,
  payload: { code, password, passwordConfirmation, history },
});
export const resetPasswordSuccess = (newPassword) => ({
  type: RESET_PASSWORD_SUCCESS,
  payload: newPassword,
});
export const resetPasswordError = (message) => ({
  type: RESET_PASSWORD_ERROR,
  payload: { message },
});

export const registerUser = (user, history) => ({
  type: REGISTER_USER,
  payload: { user, history },
});
export const registerUserSuccess = () => ({
  type: REGISTER_USER_SUCCESS,
});
export const registerUserError = (message) => ({
  type: REGISTER_USER_ERROR,
  payload: { message },
});

export const logoutUser = (history) => ({
  type: LOGOUT_USER,
  payload: { history },
});
