import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getCurrentUser } from './Utils';
import { isAuthGuardActive } from '../constants/defaultValues';

const ProtectedRoute = ({
  component: Component,
  contextWrapper: ContextWrapper,
  contextWrapperProps = {},
  roles,
  ...rest
}) => {
  const setComponent = (props) => {
    if (isAuthGuardActive) {
      const currentUser = getCurrentUser();
      if (currentUser) {
        if (roles) {
          if (roles.includes(UserRole[currentUser.role?.type])) {
            return Boolean(ContextWrapper) ? (
              <ContextWrapper {...contextWrapperProps} {...props}>
                <Component {...props} />
              </ContextWrapper>
            ) : (
              <Component {...props} />
            );
          } else {
            return (
              <Redirect
                to={{
                  pathname: '/unauthorized',
                  state: { from: props.location },
                }}
              />
            );
          }
        } else {
          return Boolean(ContextWrapper) ? (
            <ContextWrapper {...contextWrapperProps} {...props}>
              <Component {...props} />
            </ContextWrapper>
          ) : (
            <Component {...props} />
          );
        }
      } else {
        return (
          <Redirect
            to={{
              pathname: '/user/login',
              state: { from: props.location },
            }}
          />
        );
      }
    } else {
      return Boolean(ContextWrapper) ? (
        <ContextWrapper {...contextWrapperProps} {...props}>
          <Component {...props} />
        </ContextWrapper>
      ) : (
        <Component {...props} />
      );
    }
  };

  return <Route {...rest} render={setComponent} />;
};
const UserRole = {
  super_admin: 'super_admin',
  admin: 'admin',
  authenticated: 'client',
};

export { ProtectedRoute, UserRole };
