import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
// import { auth } from '../../helpers/Firebase';
import {
  LOGIN_USER,
  REGISTER_USER,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  AUTH_CHECK,
} from '../actions';

import {
  loginUserSuccess,
  loginUserError,
  registerUserSuccess,
  registerUserError,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordSuccess,
  resetPasswordError,
  authCheckSuccess,
  authCheckError,
} from './actions';

import { adminRoot, url } from '../../constants/defaultValues';
import { setCurrentUser } from '../../helpers/Utils';
import { UserRole } from '../../helpers/authHelper';
import { axiosAuth, removeToken } from '../../helpers/useAuth';

// AUTH_CHECK FETCH
const authCheckAsync = async () => {
  try {
    const { data } = await axiosAuth().get(`/users/me`);
    return data;
  } catch (error) {
    return error;
  }
};

// AUTH_CHECK WORKER
function* authCheckWorker() {
  try {
    const response = yield call(authCheckAsync);

    const item = {
      email: response.email,
      emailFrequency: response.emailFrequency,
      emailNotifications: response.emailNotifications,
      frontrunnerAcc: response.jwt,
      id: response.id,
      img: response.profilePhoto?.url,
      phone: response.phone,
      smsNotifications: response.smsNotifications,
      title: response.username,
      role: UserRole[response.role.type],
    };
    yield put(authCheckSuccess(item));
  } catch (error) {
    yield put(authCheckError(false, 'Unauthorized user.'));
    // TODO -- logout user ***
    yield call(removeToken);
  }
}

// AUTH_CHECK WATCHER
export function* watchAuthCheck() {
  yield takeEvery(AUTH_CHECK, authCheckWorker);
}

const loginWithEmailPasswordAsync = async (identifier, password) => {
  try {
    const { data } = await axios.post(`${url}/auth/local/`, {
      identifier,
      password,
    });

    return data;
  } catch (error) {
    return error;
  }
};

function* loginWithEmailPassword({ payload }) {
  const { identifier, password } = payload.user;
  const { history } = payload;
  try {
    const response = yield call(
      loginWithEmailPasswordAsync,
      identifier,
      password
    );

    if (!response.message) {
      const item = {
        email: response.user.email,
        emailFrequency: response.user.emailFrequency,
        emailNotifications: response.user.emailNotifications,
        frontrunnerAcc: response.jwt,
        id: response.user.id,
        img: response.user.profilePhoto?.url,
        phone: response.user.phone,
        role: response.user.role,
        smsNotifications: response.user.smsNotifications,
        title: response.user.username,
      };

      // set storage for jwt, not user info instead
      setCurrentUser(item);
      yield put(loginUserSuccess(item));
      history.push(adminRoot);
    } else {
      yield put(loginUserError(response.message));
    }
  } catch (error) {
    yield put(loginUserError(error));
  }
}

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

const registerWithEmailPasswordAsync = async (
  email,
  username,
  password,
  role
) => {
  try {
    const { data } = await axios.post(`${url}/auth/local/register`, {
      email,
      username,
      password,
      role,
      confirmed: true,
    });
    return data;
  } catch (error) {
    return error;
  }
};

function* registerWithEmailPassword({ payload }) {
  const { email, username, password, role } = payload.user;
  const { history } = payload;
  try {
    const { message, user } = yield call(
      registerWithEmailPasswordAsync,
      email,
      username,
      password,
      role
    );

    if (!message && user) {
      yield put(registerUserSuccess());
      history.replace('/app/account');
    } else {
      yield put(registerUserError(message));
    }
  } catch (error) {
    yield put(registerUserError(error));
  }
}

export function* watchRegisterUser() {
  yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

function* logout({ payload }) {
  const { history } = payload;
  yield call(setCurrentUser);
  yield put(authCheckError(false, 'Unauthorized.'));
  history.push(adminRoot);
}

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout);
}

const forgotPasswordAsync = async (email) => {
  const { data } = await axios.post(`${url}/auth/forgot-password`, {
    email,
  });
  return data;
};

function* forgotPassword({ payload }) {
  const {
    forgotUserMail: { email },
    history,
  } = payload;

  try {
    const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
    if (forgotPasswordStatus.ok) {
      yield put(forgotPasswordSuccess('success'));
      if (!payload.isLoggedIn) {
        history.push('/user/login');
      }
    } else {
      yield put(forgotPasswordError(forgotPasswordStatus.message));
    }
  } catch (error) {
    yield put(forgotPasswordError(error));
  }
}

export function* watchForgotPassword() {
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const resetPasswordAsync = async (code, password, passwordConfirmation) => {
  const { data } = await axios.post(`${url}/auth/reset-password`, {
    code,
    password,
    passwordConfirmation,
  });
  return data;
};

function* resetPassword({ payload }) {
  const { password, passwordConfirmation, code, history } = payload;

  try {
    const resetPasswordStatus = yield call(
      resetPasswordAsync,
      code,
      password,
      passwordConfirmation
    );

    const { jwt, user } = resetPasswordStatus;

    if (jwt) {
      const item = {
        id: user.id,
        title: user.username,
        img: user.profilePhoto?.url,
        date: user.updated_at,
        role: UserRole[user.role.name],
      };
      setCurrentUser(item);
      yield put(resetPasswordSuccess('success'));
      yield call(setCurrentUser);
      yield put(authCheckError(false, 'Unauthorized.'));
      history.push(adminRoot);
    } else {
      yield put(resetPasswordError(resetPasswordStatus.message));
    }
  } catch (error) {
    let { message } = error.response.data;
    message = Array.isArray(message) ? message[0].messages[0].message : message;
    yield put(resetPasswordError(message));
  }
}
export function* watchResetPassword() {
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchForgotPassword),
    fork(watchResetPassword),
    fork(watchAuthCheck),
  ]);
}
